@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

.circle-clip {
  position: fixed;
  /* background-image: linear-gradient(0deg , rgb(142, 45, 226)50%, rgb(161, 161, 161)); */
  background-color: #0e93e6;
  width: 100%;
  height: 120vh;
  clip-path: circle(70% at 90% 0%);
  top: 0;
  z-index: -2;
}
.rings1 {
  position: absolute;
  /* background-image: linear-gradient(45deg , rgb(142, 45, 226), rgb(74, 0, 224) 25%); */
  background-color: rgb(91, 117, 202);
  width: 100%;
  height: 85vh;
  clip-path: circle(10% at 5% 80%);
  z-index: -2;
}

.inner-ring1 {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 85vh;
  clip-path: circle(9% at 5% 80%);
}

.rings2 {
  position: absolute;
  /* background-image: linear-gradient(90deg, rgb(142, 45, 226) 22%, rgb(74, 0, 224) 30%); */
  background-color: rgb(91, 117, 202);
  width: 100%;
  height: 90vh;
  clip-path: circle(5% at 25% 90%);
  z-index: -2;
}

.inner-ring2 {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 90vh;
  clip-path: circle(4.5% at 25% 90%);
}

.rings3 {
  position: absolute;
  /* background-image: linear-gradient(90deg , rgb(142, 45, 226) 28%, rgb(74, 0, 224) 34%); */
  background-color: rgb(91, 117, 202);
  width: 100%;
  height: 89vh;
  clip-path: circle(3% at 30% 70%);
  z-index: -2;
}

.inner-ring3 {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 89vh;
  clip-path: circle(2.6% at 30% 70%);
}
.obj {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
  align-content: space-around;
}

.main-container {
  width: 100%;
  margin-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 20vh;
  position: relative;
}

@media only screen and (max-width: 960px) {
  .rings1, .rings2, .rings3{
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .circle-clip {
    position: absolute;
    background-color: #0e93e6;
    width: 100%;
    height: 100vh;
    clip-path: circle(70% at 50% 30%);
    top: 0vh;
    z-index: -2;
  }
}
